import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@coreui/coreui/dist/css/coreui.min.css';
import './assets/css/main.css';
import Toaster from '@meforma/vue-toaster';
const app = createApp(App);
app.use(Toaster,{
    position: "top-right"
});
app.use(router);
app.mount("#app");
// createApp(App).use(router).mount('#app');