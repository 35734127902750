<template>
<div class="d-flex flex-column justify-content-center align-items-start center h-100 p-5 offset-md-2">
    <h5 class="pb-4 fw-bold">Emitir Certificado</h5>
    <p class="pb-5">Informe o nome do usuário e a senha que foram obtidas na primeira parte do processo de emissão.</p>
    <CForm class="w-100">
        <div class="mb-3">
            <CFormLabel for="userName">Solicação</CFormLabel>
            <CFormInput required v-model='solicitacao' v-on:keyup.enter="nextPlease" type="text" id="userName" placeholder="Ex: 01ED184010150778 11116521754247" v-mask="requestTokens" />
        </div>
        <div class="mb-3">
            <CFormLabel for="password">Senha</CFormLabel>
            <div class="input-grup-password">
                <CFormInput required v-model='senha' placeholder="Digite a senha aqui" id="password" :type='passwordFieldType' />
                <button class="showhide" type="button" @click="switchVisibility"><img data-v-3082068b="" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNi40NzEiIGhlaWdodD0iMTMuNzc0IiB2aWV3Qm94PSIwIDAgMjYuNDcxIDEzLjc3NCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2MzYzZkMjtzdHJva2U6I2MzYzZkMjtzdHJva2Utd2lkdGg6MC4zcHg7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yOTMuMzMzIC00NDEuODUpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTMxOS41NzMsNDQ4LjU2N2EyMy4zMjksMjMuMzI5LDAsMCwwLTMuOC0zLjI4MSwyMC4zNCwyMC4zNCwwLDAsMC00LjEyNS0yLjI1NiwxMy4wNDIsMTMuMDQyLDAsMCwwLTEwLjE2MywwLDIwLjM0LDIwLjM0LDAsMCwwLTQuMTI1LDIuMjU2LDIzLjMyOCwyMy4zMjgsMCwwLDAtMy44LDMuMjgxLjI1Ni4yNTYsMCwwLDAsMCwuMzM5LDIzLjMyOCwyMy4zMjgsMCwwLDAsMy44LDMuMjgxLDIwLjM0MiwyMC4zNDIsMCwwLDAsNC4xMjUsMi4yNTYsMTMuMDQyLDEzLjA0MiwwLDAsMCwxMC4xNjMsMCwyMC4zNDIsMjAuMzQyLDAsMCwwLDQuMTI1LTIuMjU2LDIzLjMyOSwyMy4zMjksMCwwLDAsMy44LTMuMjgxQS4yNTYuMjU2LDAsMCwwLDMxOS41NzMsNDQ4LjU2N1ptLTQuMSwzLjIwOGExNC4wMjksMTQuMDI5LDAsMCwxLTE3LjgwNiwwLDI0LjA5MiwyNC4wOTIsMCwwLDEtMy41NjEtMy4wMzgsMjQuMSwyNC4xLDAsMCwxLDMuNTYxLTMuMDM4LDE0LjAyOSwxNC4wMjksMCwwLDEsMTcuODA2LDAsMjQuMSwyNC4xLDAsMCwxLDMuNTYxLDMuMDM4QTI0LjA5MSwyNC4wOTEsMCwwLDEsMzE1LjQ3Miw0NTEuNzc1WiIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNDY5LjgsNDY0YTUuOCw1LjgsMCwxLDAsNS44LDUuOEE1LjgwNSw1LjgwNSwwLDAsMCw0NjkuOCw0NjRabTAsMTEuMDg2YTUuMjg3LDUuMjg3LDAsMSwxLDUuMjg3LTUuMjg3QTUuMjkzLDUuMjkzLDAsMCwxLDQ2OS44LDQ3NS4wODZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTYzLjIzIC0yMS4wNjIpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik01MzMuODQ3LDUzMC45YTIuOTQ2LDIuOTQ2LDAsMSwwLDIuOTQ2LDIuOTQ2QTIuOTUsMi45NSwwLDAsMCw1MzMuODQ3LDUzMC45Wm0wLDUuMzgxYTIuNDM1LDIuNDM1LDAsMSwxLDIuNDM1LTIuNDM1QTIuNDM3LDIuNDM3LDAsMCwxLDUzMy44NDcsNTM2LjI4MloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMjcuMjc4IC04NS4xMSkiLz48L2c+PC9zdmc+" alt=""></button>
            </div>
        </div>
        <div class="d-flex my-4 w-100">
            <button type="button" @click="validar" class="btn btnPrimary align-self-center">Continuar<div v-if="requestOn" class="spinner-border spinner-border-sm ms-2" role="status"></div></button>
            <button type="button" @click="verificar" class="btn btnSecondary align-self-end">Verificar Emissão</button>
        </div>
    </CForm>

</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import {
    CForm,
    CFormLabel,
    CFormInput,

} from '@coreui/vue'
import {
    env,
    requestMessageError
} from '@/shared/utils';
import {
    mask
} from 'vue-the-mask';

// import {TheMask} from 'vue-the-mask'

export default {
    name: 'autenticar',
    components: {
        CForm,
        CFormLabel,
        CFormInput,
        // TheMask
    },

    data() {
        return {
          
            requestOn: false,
            visibleLiveDemo: false,
            contentVerify: {},
            arLink1: "",
            arLink2: "",
            solicitacao: '',
            senha: '',
            passwordFieldType: 'password',
            requestTokens: {
                mask: 'FFFFFFFFFFFFFFFF DDDDDDDDDDDDDD',
                tokens: {
                    'F': {
                        pattern: /[0-9a-fA-F]/,
                        transform: function (v) {
                            return v.toLocaleUpperCase();
                        }
                    },
                    'D': {
                        pattern: /\d/,
                        transform: function (v) {
                            return v.toLocaleUpperCase();
                        }
                    }
                }
            }
        };
    },
    mounted: function(){
        sessionStorage.removeItem('solicitacao');
        sessionStorage.removeItem('senha');
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        nextPlease: function () {
            document.getElementById('password').focus();
        },
        verificar() {
            sessionStorage.setItem("solicitacao", this.solicitacao);
            sessionStorage.setItem("senha", this.senha);
            this.$router.push({name:"detail"});
        },
        validar() {
            this.requestOn = true;
            var data = {
                solicitacao: this.solicitacao,
                senha: this.senha
            }
            axios({
                url: `${env.urlServer}/validar`,
                method: 'POST',
                data: data,
            }).then(response => {
                var data = response.data;
                console.log(data)
                if (response.data.mensagem) {
                    if (response.data.mensagem.erro) {
                        this.$toast.error(requestMessageError(data));
                        this.requestOn = false;
                    }
                    if (response.data.mensagem.emissao) {
                        if (response.data.mensagem.emissao.status !== 'undefined') {
                            var status = response.data.mensagem.emissao.status;
                            if (status == 0 || status == 9) {
                                this.requestOn = false;
                                sessionStorage.setItem("solicitacao", this.solicitacao);
                                sessionStorage.setItem("senha", this.senha);
                                sessionStorage.setItem("dados",JSON.stringify(response.data));
                                this.$toast.success(data.mensagem.emissao.obs);
                                this.$router.push({
                                    name: "emission"
                                }); 
                            }else{
                                sessionStorage.setItem("solicitacao",this.solicitacao);
                                sessionStorage.setItem("senha", this.senha);
                                this.$toast.success(data.mensagem.emissao.obs);
                                this.$router.push({name:"detail"});
                                this.requestOn = false;
                            }
                        }
                    }
                } else {
                    this.$toast.error(requestMessageError(response.data));
                    this.requestOn = false;
                }
            }).catch(error => {
                this.$toast.error(requestMessageError(error?.response));
                this.requestOn = false;
            })
        }
    },
    directives: {
        mask
    }
}
</script>

<style scoped>

</style>
