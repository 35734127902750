<template>
<div class="d-flex flex-column justify-content-center align-items-start center h-100 p-5 offset-md-2">
    <h5 class="pb-4 fw-bold">Emitir Certificado</h5>
    <p class="pb-5">Informe os dados abaixo para finalizar o processo de emissão.</p>
    <CForm class="w-100">
        <div class="mb-3">
            <CFormLabel for="solicitacao">Solicação</CFormLabel>
            <CFormInput disabled required v-model='solicitacao' v-on:keyup.enter="nextPlease" type="text" id="solicitacao" placeholder="Ex: 01ED184010150778 11116521754247" v-mask="requestTokens" />
        </div>
        <div class="mb-3">
            <CFormLabel for="password">CSR</CFormLabel>
            <CPopover content="CSR...." placement="right">
                <template #toggler="{ on }">
                    <CButton class="messagePopup" v-on="on">?</CButton>
                </template>
            </CPopover>
            <CFormTextarea required placeholder="Cole o CSR aqui" id="csr" rows="6" v-model='csr' />
        </div>
        <div v-if="digitalterm_hash">
            <div class="w-100 d-flex justify-content-end">
                <button type="button" @click="downloadHash" class="btn btnSecondary align-self-center">Download do hash do termo</button>
            </div>
            <div class="mb-3 mt-2">
                <CFormLabel for="password">Assinatura do Termo Digital</CFormLabel>
                <CPopover content="Assinatura do Termo Digital...." placement="right">
                    <template #toggler="{ on }">
                        <CButton class="messagePopup" v-on="on">?</CButton>
                    </template>
                </CPopover>
                <CFormTextarea required placeholder="Cole o PKCS#1 aqui" id="csr" rows="6" v-model='termo_digital' />
            </div>
        </div>
        <div class="d-flex my-4 w-100">
            <button type="button" @click="toEmission" class="btn btnPrimary align-self-center">Emitir<div v-if="requestOn" class="spinner-border spinner-border-sm ms-2" role="status"></div></button>
            <router-link :to="{name:'autenticar'}" class="btn btnSecondary align-self-end">Voltar</router-link>
        </div>
    </CForm>
</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import {
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CPopover
} from '@coreui/vue'
import {
    env,
    requestMessageError
} from '@/shared/utils';
import {
    mask
} from 'vue-the-mask';

// import {TheMask} from 'vue-the-mask'

export default {
    name: 'emission',
    components: {
        CForm,
        CFormLabel,
        CFormInput,
        CFormTextarea,
        CPopover
        // TheMask
    },

    data() {
        return {
            data: sessionStorage.getItem('dados'),
            requestOn: false,
            visibleLiveDemo: false,
            contentVerify: {},
            digitalterm_hash: '',
            solicitacao: sessionStorage.getItem("solicitacao"),
            senha: sessionStorage.getItem("senha"),
            csr: '',
            termo_digital: '',
            passwordFieldType: 'password',
            requestTokens: {
                mask: 'FFFFFFFFFFFFFFFF DDDDDDDDDDDDDD',
                tokens: {
                    'F': {
                        pattern: /[0-9a-fA-F]/,
                        transform: function (v) {
                            return v.toLocaleUpperCase();
                        }
                    },
                    'D': {
                        pattern: /\d/,
                        transform: function (v) {
                            return v.toLocaleUpperCase();
                        }
                    }
                }
            }
        };
    },
    methods: {
        downloadHash() {
            var blob = new Blob([this.digitalterm_hash]);
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'digitalterm_hash.hex';
            link.click();
            link.remove();
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        nextPlease: function () {
            document.getElementById('password').focus();
        },
        toEmission() {
            this.requestOn = true;
            var data = {
                solicitacao: this.solicitacao,
                senha: this.senha,
                csr: this.csr,
                digitalterm_signature: this.termo_digital
            };
            axios({
                url: `${env.urlServer}/emitir`,
                contentType: 'application/json',
                method: 'POST',
                data: data,
            }).then(response => {
                console.log(response.data);
                if (response.data.mensagem) {
                    if (response.data.mensagem.obs) {
                        this.$toast.success(response.data.mensagem.obs)
                        this.$router.push({
                            name: "detail"
                        });
                    }
                    if (response.data.mensagem.erro) {
                        this.$toast.error(response.data.mensagem.erro.mensagem);
                    }

                }
                this.requestOn = false;
            }).catch(error => {

                this.requestOn = false;
                this.$toast.error(requestMessageError(error?.response));
            })
        },

    },
    mounted: function () {
        var data = JSON.parse(this.data);
        let self = this;
        const solicitacao = sessionStorage.getItem("solicitacao");
        const senha = sessionStorage.getItem("senha");
        if ((!solicitacao || solicitacao == undefined) && (!senha || senha == undefined)) {
            self.$router.push({
                name: 'autenticar'
            })
        }
        if (data.mensagem) {
            if (data.mensagem.emissao) {
                if (data.mensagem.emissao.digitalterm_hash) {
                    this.digitalterm_hash = data.mensagem.emissao.digitalterm_hash;
                }
            }
        }
    },

    directives: {
        mask
    }

}
</script>

<style scoped>
input {
    border-radius: 10px;
    width: 100%;
}

textarea {
    border-radius: 10px;
    width: 100%;
}

.input-grup-password {
    display: flex;
    position: relative;
}

.input-grup-password input {
    padding-right: 55px;
}

.input-grup-password .showhide {
    position: absolute;
    right: 0;
    top: 5px;
    margin-bottom: auto;
    /* height: auto; */
    background: none;
    border: none;
}
</style>
