<template>
  <div
    class="
      d-flex
      flex-column
      justify-content-center
      align-items-start
      center
      h-100
      p-5
      offset-md-2
    "
  >
    <h5 class="pb-4 fw-bold">Detalhes da Emissão</h5>
    <CForm class="w-100">
      <div class="mb-3">
        <CFormLabel for="solicitacao">Solicitação</CFormLabel>
        <CFormInput
          disabled
          v-model="storeData.solicitacao"
          type="text"
          id="solicitacao"
        />
      </div>
      
      <div class="mb-3" v-if="crt">
        <CFormLabel for="password">CRT</CFormLabel>
        <CFormTextarea
          readonly
          id="crt"
          rows="6"
          v-model="crt"
        />
      </div>
      <div class="mb-3" v-if="message">
        <CAlert :color="['0', '1'].includes(status) ? 'warning' : 'danger'">[{{status}}] {{message}}
          <span color="danger" v-if="['8', '9'].includes(status)"><br>Entre em contato com o suporte!</span>
        </CAlert>
      </div>
      <div class="mb-3" style="text-align: center" v-if="counter > 0">
        <div class="spinner-border text-info pm-3" role="status">
          <span class="sr-only"></span>
        </div> <br>
        Uma nova requisição será realizada em {{counter}} segundos...
      </div>
      <div class="d-flex my-4 w-100">
        <router-link to="/" class="btn pe-5 align-self-center"
          >Voltar</router-link
        >
        <button
          v-if="crt"
          type="button"
          @click="download"
          class="btn btnPrimary align-self-center"
        >
          Download
          <div
            v-if="requestOn"
            class="spinner-border spinner-border-sm ms-2"
            role="status"
          ></div>
        </button>
      </div>
    </CForm>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CFormTextarea,
  CAlert
} from "@coreui/vue";
import { env } from "@/shared/utils";
import { mask } from "vue-the-mask";
// import {TheMask} from 'vue-the-mask'

export default {
  name: "emission",
  components: {
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CAlert
    // TheMask
  },

  data() {
    return {
      requestOn: false,
      storeData: {},
      crt: "",
      message: "",
      status: null,
      counter: 0,
      interval: null
    };
  },
  mounted() {
    // solicitacao: "113D2202035F622D 61904089000105",
    // // solicitacao: "113D220120653EA1 00011423351002",
    // senha: "soluti@2022"
    this.storeData.solicitacao = sessionStorage.getItem('solicitacao');
    this.storeData.senha = sessionStorage.getItem('senha');
    if (!this.storeData.solicitacao || !this.storeData.senha) {
      this.$router.push({ 
        name: 'autenticar'
      })
    } else {
      this.validate();
    }
  },
  methods: {
    validate() {
      this.status = null;
      this.crt = this.message = "";
      this.requestOn = true;
      var data = {
        solicitacao: this.storeData.solicitacao,
        senha: this.storeData.senha,
      }
      axios({
        url: `${env.urlServer}/validar`,
        contentType: "application/json",
        method: "POST",
        data,
      }).then((response) => {
        console.log(response)
        this.status = response.data?.mensagem?.emissao?.status;
        if (this.status == 2) {
          this.getCertificateData(data);
        } else {
          if (["0", "1"].includes(this.status)) {
            this.startCountValidate();
          }
          if (response.data?.mensagem?.emissao?.obs) {
            this.message = response.data.mensagem.emissao.obs;
          } else {
            this.status = 10;
            this.message = "Erro desconhecido";
          }
        }
      }).catch((error) => {
        console.error(error.response.data);
        this.$toast.error(error.response.data.mensagem);
      }).finally(() => {
          this.requestOn = false;
      })
    },

    startCountValidate() {
      this.counter = 30; //seconds
      this.interval = setInterval(() => {
        if (this.counter <= 0) {
          clearInterval(this.interval);
          this.validate();
        } else {
          this.counter--;
        }
      }, 1000);
    },

    getCertificateData(data) {
      this.requestOn = true;
      axios({
        url: `${env.urlServer}/recuperar`,
        contentType: "application/json",
        method: "POST",
        data,
      }).then((response) => {
        console.log(response);
        if (response.data?.mensagem?.data) {
          this.crt = response.data.mensagem.data
        }
      }).catch((error) => {
        console.error(error);
        this.$toast.error(error.response.data.mensagem);
      }).finally(() => {
          this.requestOn = false;
      })
    },

    copyCertificado() {
      navigator.clipboard.writeText(this.crt);
      this.$toast.success("Certificado copiado na área de transferencia");
    },

    download() {
      const url = window.URL.createObjectURL(new Blob([this.crt]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = `${this.storeData.solicitacao}.crt`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },

  directives: { mask },
};
</script>

<style scoped>
input {
  border-radius: 10px;
  width: 100%;
}
textarea {
  border-radius: 10px;
  width: 100%;
}
.input-grup-password {
  display: flex;
  position: relative;
}
.input-grup-password input {
  padding-right: 55px;
}

.input-grup-password .showhide {
  position: absolute;
  right: 0;
  top: 5px;
  margin-bottom: auto;
  /* height: auto; */
  background: none;
  border: none;
}
</style>
