<template>
    <div class="d-flex flex-column justify-content-center align-items-start center h-100 p-5 offset-md-2">
        <h5 class="pb-4 fw-bold">Olá, bem-vindo ao Vault ID!</h5>
        <p class="pb-5">Caso você ainda não tenha emitido o seu Certificado Digital, clique no botão abaixo.</p>
        <router-link to="/emissao/emission" class="btn btnPrimary align-self-center">Emitir Certificado Digital</router-link>
        <!-- <router-link to="/" class="py-5 align-self-center">Autenticar no Portal</router-link> -->
    </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'main',
}
</script>