export function requestError(error, context = null) {
  console.log(error);
  if (error.response) {
    error = error.response;
  }
  if (error.data) {
    console.log(error.data);
    if (context && error.data.errorBodyValidation) {
      context.errors = error.data;
      alert("Erro ao validar campos do formulário.");
    } else if (!error.data.message) {
      return alert(error.statusText);
    } else {
      return alert(error.data.message);
    }
  } else if (error.statusText) {
    console.log(error.statusText);
    return alert(error.statusText);
  } else {
    console.log(error);
    alert("Erro inesperado!");
  }
}

export function requestMessageError(error){
  console.log(error);
  if(error){
    if(error.mensagem){
      if(error.mensagem.erro){
        if(error.mensagem.erro.mensagem){
          return(error.mensagem.erro.mensagem);
        }
      }
    }if(error.data){
      if(error.data.message){
        return(error.data.message);
      }
      else{
        return(`Erro: ${JSON.stringify(error.data)}`);
      }
    }
    else{
      return(`Erro: ${JSON.stringify(error)}`);
    }
  }
  else{
    return(`Erro: ${JSON.stringify(error)}`);
  }
}

export const env = {
  urlServer: process.env['VUE_APP_URL_SERVER'] || 'https://apiemissaoweb.vaultid.com.br',
}