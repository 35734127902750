<template>
    <CContainer fluid>
      <CRow>
        <CCol class="container-bg-login"></CCol>
        <CCol class="bg-login-mobile">
          <router-view v-slot="{ Component }">
            <transition name="slide" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </CCol>
      </CRow>
    </CContainer>
</template>

<script>
// @ is an alias to /src
import { CContainer,CRow,CCol } from '@coreui/vue'
import Main from '../components/Home/index.vue';


export default {
  name: 'Home',
  components: {
    CContainer,
    CRow,
    CCol,
    Main
  }
}
</script>
<style scoped>
  .container-bg-login{
      height: auto;
      min-height: 100vh;
      background-image: url('../assets/img/BARRA-LATERAL.2e886b1.png');
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100%;
  }

  @media (max-width:768px) {
    .container-bg-login{
       display: none;
    }
    .bg-login-mobile{
        background-image: url('../assets/img/BARRA-LATERAL-HOME.fc8c70c.png');
        background-size: 100vh;
        background-color: rgba(255, 255, 255, 0.404);
        background-size: 100vh;
        background-position: top;
        background-repeat: no-repeat;
        min-height: 100vh;
        height: auto;
    }
  }
</style>
