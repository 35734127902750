import { createWebHistory, createRouter } from "vue-router";
import Home from '../views/Home.vue'
import Emissao from '../components/Form/emission.vue';
import EmissaoDetalhe from '../components/Form/emission-detail.vue';
import Autenticar from '../components/Form/autenticar.vue';

// async function beforeEnter(to, from, next ){
//   try {
//     window.document.title = to.meta && to.meta.title ? to.meta.title : 'Home';
//     var hasPermission = false;
//       if(hasPermission){
//         next();
//       }
//       else{
//         next({
//           name:'emission',
//           query:{redirectFrom: to.fullPath}
//         })
//       }
    
//   } catch (error) {
//     next({
//       name:'emission',
//       query:{redirectFrom: to.fullPath}
//     })
//   }

// }
function beforeEnterMetaTitle(to){
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'Home';
}
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: About,
  //   meta:{
  //     title: 'Home',
  //   },
  //   beforeEnter:beforeEnter
  // },
  {
    path: '/',
    name: 'Login',
    component: Home,
    meta: { 
      title: 'Emissão de Certificado Digital',
    },
    beforeEnter:beforeEnterMetaTitle,
    children:[
      {
        name:'autenticar',
        path:'',
        component: Autenticar,
        meta: {
          title:"Emissão - Autenticar" ,
          transition: 'slide-left' 
        },
        beforeEnter:beforeEnterMetaTitle,
      },
      {
        name:'emission',
        path:'emitir',
        component: Emissao,
        meta: {
          title:"Emissão - Certificado" ,
          transition: 'slide-left' 
        },
        beforeEnter:beforeEnterMetaTitle,
      },
      {
        name:'detail',
        path:'detail',
        component: EmissaoDetalhe,
        meta: {
          title:"Detalhe Emissão - Certificado" ,
          transition: 'slide-left' 
        },
        beforeEnter:beforeEnterMetaTitle,
      },

    ]
  }
 
]

const router = createRouter({
  history:createWebHistory(),
  routes
})

export default router;
